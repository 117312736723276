.container-header {
    .icon-header {
    }
}
.text-header {
    font-size: 10px !important;
}
.content-center {
    font-size: 23px;
    justify-content: center;
}
.container-order {
    background-color: #f3f4f6;
    .image-product {
        width: 50px;
        height: 50px;
    }
    .time-order {
        font-size: 10px;
        color: #737171;
    }
    .name-prod {
        font-size: 14px;
        font-weight: 600;
    }
    .quantity {
        font-size: 13px;
        color: #737171;
    }
    .item-order {
        padding: 15px;
        z-index: 1;
        background-color: #fff;
        margin-bottom: 12px;
    }
    .total-monney {
        font-size: 13px;
        font-weight: 600;
    }
    .div-info {
        margin-top: auto;
        margin-bottom: auto;
        // font-weight: 600;
        padding: 3px 8px;
        border-radius: 12px;
        display: flex;
        width: auto;
    }
    .address {
        color: #12a14e;
        background-color: #edfff5;
        margin-top: 5px;
    }
    .phone {
        color: rgb(9, 86, 142);
        background-color: rgb(239, 248, 255);
        font-size: 13px;
    }
    .name {
        font-size: 13px;
        color: rgb(18, 18, 153);
        background-color: rgb(241, 241, 252);
        margin-right: 10px;
        margin-left: 10px;
    }
}

.header-active {
    color: #fc2b54;
    font-weight: 600;
}

.status1 {
    background-color: #ea2c00;
    color: #fff;
}
.status2 {
    background-color: #ea733b;
    color: #fff;
}
.status3 {
    background-color: #4cbd9b;
    color: #fff;
}
.status4 {
    background-color: #4488c5;
    color: #fff;
}
