.wrapper {
    margin-top: 70px !important;
    box-shadow: 10px 10px 5px 5px #ccc;
}
.banner {
    height: 100%;
    width: 100%;
}
.padding-login {
    padding: 70px 100px;
}
.login {
    border: 1px solid #ccc;
    h2 {
        text-align: center;
        font-size: 35px;
    }
    p {
        text-align: center;
    }
}
.div-input {
    margin: 20px 0;
}
.input {
    padding: 7px 12px;
    margin: 5px 0;
    border: 1px solid black;
    border-radius: 5px;
}
.register {
    margin-top: 20px;
}

.error {
    color: red;
    font-size: 1.4rem;
}
.error-input {
    padding: 7px 12px;
    border: 1px solid red;
    border-radius: 5px;
}
