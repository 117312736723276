.container-create .MuiFormControl-root {
    width: 100% !important;
    border-radius: 2px !important;
}
.container-create .MuiInputBase-root {
    background-color: #fff !important;
}
.container-create .MuiInputBase-input {
    padding: 14px 53px 14px 15px !important;
}
.container-create {
    padding-bottom: 140px;
}
