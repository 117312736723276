.comment-item {
    margin: 19px 0;
}
.account-item {
    .item-info {
        font-size: 12px;
        margin-left: 8px;
        font-weight: 600;
        .nickname {
            display: flex;
            align-items: center;
        }
    }
}

.account-item {
    .avatar {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        object-fit: cover;
    }
}
.account-item {
    .time {
        font-size: 13px;
    }
}
.star {
    font-size: 11px;
    color: #ffdb3d;
}
.comment-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr); // 4 images per row
    gap: 10px; // Space between images
    margin-top: 5px;
}

.comment-image {
    width: 100%; // Take full width of grid item
    height: auto; // Maintain aspect ratio
    object-fit: cover;
    border-radius: 5px; // Rounded corners
    border-radius: 0 !important;
    aspect-ratio: 1 / 1;
}
