.flex {
    display: flex;
}
.nav-payment {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    // z-index: 2;
    height: 53px;
    // min-height: 70px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}
.fixed-bottom {
    z-index: 10;
    position: fixed; // Ensures the footer stays at the bottom
    bottom: 0; // Aligns the footer at the bottom of the page
    width: 100%;
    background-color: #fff;
}
.title-page {
    font-size: 16px;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 3px;
}
.line {
    height: 5px;
    background-color: whitesmoke;
    // margin: 20px 18px;
}
.line-small {
    height: 1px;
    background-color: whitesmoke;
}
.container-address {
    background-color: #f2f2f2;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    border-radius: 6px;
    padding: 8px 0;
    justify-content: center;
    display: flex;
    .icon-plus {
        font-size: 26px;
    }
}
.container-product {
    margin: 15px 18px;
    .image-product {
        width: 100px;
        height: 100px;
    }
    .icon-shop {
        font-size: 20px;
    }
    .info-shop {
        margin-bottom: 15px;
    }
    .avatar-shop {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        object-fit: cover;
    }
    .name {
        font-weight: 600;
    }
    .name-product,
    .type-product {
        color: #585858;
    }
    .icon-check {
        color: rgb(238, 210, 26);
        margin-right: 2px;
        font-size: 10px;
    }
    .div-icon-check {
        font-size: 11px;
        background-color: #f8f8f8;
        padding: 1px 4px;
        margin-right: 4px;
    }
    .price {
        font-weight: 600;
    }
    .price-old {
        font-size: 12px;
        text-decoration: line-through;
        color: #737171;
    }
    .icon-sub-product,
    .number-product,
    .icon-add-product {
        width: 28px;
        border-top: 1px solid #e9e8e8;
        border-left: 1px solid #e9e8e8;
        border-bottom: 1px solid #e9e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .number-product {
        font-weight: 600;
    }
    .icon-add-product {
        border-right: 1px solid #e9e8e8;
    }
}
.voucher {
    margin: 15px 18px;
    .title {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 15px;
    }
}
.container-order {
    margin: 15px 18px;
    line-height: 30px;
    .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
.container-payment-method {
    margin: 15px 18px;
    .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}
.container-policy {
    margin: 15px 18px;
    padding-bottom: 118px;
}

.footer {
    padding: 15px 18px;
    border: 1px solid whitesmoke;
    .btn-order {
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        background-color: #fc2b54;
        color: white;
        border-radius: 2px;
        flex-grow: 1;
        text-align: center;
        padding: 10px 0;
    }
}
.content-center {
    justify-content: center;
}
.container-modal-address {
    .nav-payment {
        align-items: center;
        // position: -webkit-sticky;
        // position: sticky;
        // top: 0;
        z-index: 100;
        height: 53px;
        // min-height: 70px;
        width: 100%;
        background-color: #f5f5f5;
    }
    .flex {
        display: flex;
    }
    .title-page {
        font-size: 17px;
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .container {
        background-color: #f5f5f5;
        margin: 0 7px;
        .info {
            color: rgb(98, 98, 98);
            padding: 15px 0 8px 15px;
        }
        input {
            width: 100%;
            font-size: 15px;
            border: none;
            border-radius: 2px;
            padding: 18px 15px;
            margin-bottom: 1px;
        }

        input:focus {
            outline: none;
        }
        .div-phone {
            background-color: #ffffff;
            align-items: center;
            p {
                white-space: nowrap;
            }
        }
        .setting {
            background-color: #ffffff;
            padding: 5px 15px;
        }
    }
    .line {
        height: 1px;
        width: 100%;
        background-color: #ccc;
    }
    .footer {
        margin: 15px 15px;
    }
    .btn-save-address {
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        background-color: #fc2b54;
        color: white;
        border-radius: 5px;
        flex-grow: 1;
        text-align: center;
        padding: 10px 0;
        margin: 10px 0;
    }
    .btn-save-address:disabled {
        // background-color: #ccc;
        // color: #666; /* Màu chữ xám nhạt */
        // cursor: not-allowed; /* Đổi con trỏ thành dấu cấm */
        opacity: 0.6; /* Giảm độ đậm */
        pointer-events: none; /* Vô hiệu hóa tất cả các sự kiện */
    }

    .bold {
        font-weight: 600;
    }
}
.container-model-info-address {
    z-index: 50;
}
.title-address {
    font-size: 17px;
    font-weight: 600;
    margin-top: 17px;
    margin-bottom: 20px;
}
// .modal-set-address {
    .add-button {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    .cancel-button {
        padding: 10px 20px;
        background-color: #6c757d;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    .button-container {
        display: flex;
        justify-content: center;
    }
// }
.option .MuiTypography-root{
font-size: 15px !important;
font-weight: 600 !important;
}
