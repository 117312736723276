.nav-payment {
    align-items: center;
    // position: -webkit-sticky;
    // position: sticky;
    // top: 0;
    z-index: 100;
    height: 53px;
    // min-height: 70px;
    width: 100%;
    // background-color: #f5f5f5;
}
.flex {
    display: flex;
}
.title-page {
    font-size: 17px;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 3px;
}

.info {
    color: rgb(98, 98, 98);
    margin-left: 15px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
}
.container {
    input {
        width: 100%;
        z-index: 50;
        font-size: 15px;
        border: none;
        border-radius: 2px;
        padding: 14px 15px;
    }
    background-color: #f5f5f5;
    margin: 20px 7px;
    input:focus-visible {
        outline: none;
    }
    .div-phone {
        background-color: #ffffff;
        align-items: center;
        p {
            white-space: nowrap;
        }
    }
    .setting {
        background-color: #ffffff;
        padding: 5px 15px;
    }
}
.line {
    height: 1px;
    width: 100%;
    background-color: #ccc;
}
// .footer {
//     margin: 15px 15px;
// }
.btn-save-address {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    background-color: #fc2b54;
    color: white;
    border-radius: 5px;
    flex-grow: 1;
    text-align: center;
    padding: 10px 0;
    margin: 10px 0;
}

.error-input {
    padding: 7px 12px;
    border: 1px solid red;
    border-radius: 5px;
}
.step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 8px;
    z-index: 50;
    background-color: #f5f5f5;
    font-size: 14px;
}

.step {
}
.step-counter {
    background-color: #f03;
    color: #fff;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // background: #878787;
    // color: #333;
    // font-family: YouTube Sans;

    font-weight: 800;
}
.disable-step {
    background: #878787 !important;
    color: #333 !important;
}
.step-4 {
    background: linear-gradient(-90deg, #878787, #878787 50%, #f03 0) !important;
}
.line-step {
    position: absolute;
    top: 82px;
    width: 100%;
    height: 1px;
    background-color: #555;
}
.scrollable-div {
    height: 70vh; /* Giới hạn chiều cao */
    overflow-y: auto; /* Cho phép cuộn theo trục dọc */
}
.comment-item {
    margin: 19px 0;
}
.account-item {
    .item-info {
        font-size: 12px;
        margin-left: 8px;
        font-weight: 600;
    }
}

.account-item {
    .avatar {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        object-fit: cover;
    }
}
.account-item {
    .time {
        font-size: 13px;
    }
}
.star {
    font-size: 11px;
    color: #ffdb3d;
}
.comment-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr); // 4 images per row
    gap: 10px; // Space between images
    margin-top: 5px;
}

.comment-image {
    width: 100%; // Take full width of grid item
    height: auto; // Maintain aspect ratio
    object-fit: cover;
    border-radius: 5px; // Rounded corners
    border-radius: 0 !important;
    aspect-ratio: 1 / 1;
}
.button-inline {
    padding: 1rem;
    font-size: 16px;
    background-color: #fc2b54;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
}

.button-outline {
    padding: 1rem;
    font-size: 16px;
    color: #fc2b54;
    background-color: #ffffff;
    border: 1px solid #fc2b54;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
}
.label-images-product {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    margin: 3px 0 8px 15px;
    font-weight: 600;
}
.container-comment {
    margin: 20px;
    input {
        width: 100%;
        // padding: 9px 16px;
    }
    .label-images-comment {
        position: absolute;
        right: 19px;
        top: 12px;
        color: #fc2b54;
    }
}
.footer {
    background-color: #fff;
    padding: 15px 15px;
    justify-content: space-between;
    button {
        margin: 5px 0;
    }
}
.label-image-shop {
    width: 100px; /* Đặt kích thước chiều rộng */
    height: 100px; /* Đặt kích thước chiều cao */
    border: 2px dashed rgb(133, 130, 130); /* Đường viền nét đứt */
    border-radius: 50%; /* Bo tròn thành hình tròn */
    display: flex; /* Căn giữa nội dung bên trong (nếu có) */
    justify-content: center; /* Căn giữa nội dung theo chiều ngang */
    align-items: center; /* Căn giữa nội dung theo chiều dọc */
    background-color: #fff;
}
.preview-image-shop {
    width: 100px;
    height: 100px;
    img {
        border-radius: 50%;
    }
}
.label-image-edit {
    padding: 2px 3px;
    border: 1px solid #fc2b54;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 92px;
}
.div-name-shop {
    width: 100%;
    margin-left: 30px;
    align-items: center;
    display: flex;
}
.error {
    color: #d32f2f;
    border-color: #d32f2f;
}
