.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden; /* Important to hide the circles overflowing */
}

/* Circles for background design */
.circle-container {
    position: absolute;
    top: -100px; /* Adjust to move the circles up */
    right: -50px; /* Adjust to move the circles to the right */
}

.circle {
    position: absolute;
    border-radius: 50%;
    background-color: #f5f5a1;
}

.circle.large {
    width: 250px;
    height: 250px;
    top: 0;
    right: 0;
}

.circle.small {
    width: 150px;
    height: 150px;
    top: 50px;
    right: 50px;
}

.login-header {
    margin-bottom: 1rem;
    position: absolute;
    z-index: 1; /* Ensure the header is on top of the circles */
    top: 35px;
    left: 25px;
    font-size: 25px;
    font-weight: 700;
}

.login-header p {
    font-size: 1rem;
}

.country-flag img {
    width: 30px;
    height: 20px;
    margin-top: 5px;
}

.login-brand {
  margin-top: 15px;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 60px;
    z-index: 1; /* Ensure the brand is on top of the circles */
}

.login-brand h1 {
    font-size: 3rem;
    font-weight: bold;
}

.login-brand h1 span {
    color: #fc2b54;
}

.forgot-password {
    text-align: right;
    font-weight: 600;
    font-size: 12px;
    margin-top: 5px;
}

.forgot-password a {
    color: #333;
    font-size: 0.9rem;
}

.login-button {
    padding: 1rem;
    font-size: 16px;
    background-color: #fc2b54;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
}

.or-signup {
    text-align: center;
    margin-top: 1.5rem;
}

.or-signup span {
    color: gray;
}

.social-login {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.social-button {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    cursor: pointer;
}

.social-button.google {
    color: #db4437;
}

.social-button.facebook {
    color: #4267b2;
}

.social-button.apple {
    color: #000000;
}

.register-link {
    margin-top: 2rem;
    text-align: center;
}

.register-link p {
    font-size: 1rem;
}

.register-link a {
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
.input {
  width: 100%;
  font-size: 15px;
  border: 1.5px solid #afafaf;
  border-radius: 2px;
  /* padding: 14px 15px; */
  padding: 15px 12px !important;
  /* margin-bottom: 15px; */
}
