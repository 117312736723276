.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    max-height: min(100vh - 96px, 734px);
    padding-top: 8px;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0 2px 12px;
}
