.wrapper {
    margin-top: 40px !important;
    box-shadow: 10px 10px 5px 5px #ccc;
}
.banner {
    width: 100%;
    height: 100%;
}
.padding-register {
    padding: 40px 100px;
}
.register {
    border: 1px solid #ccc;
    h2 {
        text-align: center;
        font-size: 35px;
    }
    p {
        text-align: center;
    }
}
.div-input {
    margin: 7px 0;
}
input {
    padding: 7px 12px;
    margin: 5px 0;
    border: 1px solid black;
    border-radius: 5px;
}
.div-flex-input {
    justify-content: space-between;
}
.margin {
    margin-left: 9px;
}

.login {
    margin-top: 15px;
}

.error {
    color: red;
    font-size: 1.4rem;
}
.error-input {
    padding: 7px 12px;
    border: 1px solid red;
    border-radius: 5px;
}
