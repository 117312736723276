.menu-item {
    padding: 8px;
    font-size: 1.7rem;
    font-weight: 700;
    border-radius: 4px;
    color: var(--text-color);
    transition: background-color ease-in-out 200ms;

    &.active {
        color: var(--primary);
    }

    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }
}
.title {
    margin-left: 5px;
}
